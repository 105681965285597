<template>
  <a-button style="margin-left: 8px;" @click="showModal" size="small">缴费单</a-button>
  <a-modal width="760px" class="preview" v-model:visible="visible" v-if="visible" :confirm-loading="confirmLoading" okText="导出缴费单">
    <template #title>
      <div class="diy-modal-title">
        <span>缴费单预览</span>
        <div class="confusion" @mouseover="changeActive" @mouseout="removeActive">
          <img src="../../../assets/confusion.png" alt="">
          <div class="diy-confusion-show" v-show="dataSource.modal_title_active">
            <i class="sanjiao"></i>
            1、报馆费用小计：【各项数量 * 单价】依次累加。<br />
            2、押金费用小计：【各项数量 * 押金】依次累加。<br />
            3、最终支付报馆费用合计: 押金 - 追加管理费 - 加班费 - 违约金 - 其他扣款。<br />
            4、最终退回押金合计：报馆费用小计 + 追加管理费 + 加班费 + 违约金 + 其他扣款。
          </div>
        </div>
      </div>
    </template>
    <div class="my-mask" v-if="confirmLoading">
      <div>
        <a-spin size="large" />
      </div>
    </div>
    <div class="row" id="printMe">
      <table id="table" class="my-table">
        <tbody>
          <tr class="my-tr">
            <td class="my-td" colspan="8">
              <div class="my-title">
                <img :src="dataSource.logo_url" alt="" crossorigin="anonymous" />
                <span>{{dataSource.p_name}}报馆费用订单</span>
              </div>
            </td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="8">
              主场搭建：{{dataSource.organizer}}
            </td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="8">
              地址：{{dataSource.address}}
            </td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="4">电话：{{dataSource.telephone}}</td>
            <td class="my-td" colspan="4">邮件：{{dataSource.email}}</td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="4">网址：http://www.rondexpo.com</td>
            <td class="my-td" colspan="4">
              {{dataSource.tube?(dataSource.tube).toUpperCase():''}}馆联系人：{{dataSource.person}} {{dataSource.phone}}</td>
          </tr>
          <tr class="my-tr">
            <td class="my-td my-16-bold my-center" colspan="8">付款通知</td>
          </tr>
          <tr class="my-tr">
            <td class="my-td"></td>
            <td class="my-td" colspan="3"></td>
            <td class="my-td"></td>
            <td class="my-td"></td>
            <td class="my-td" colspan="2" style="color:red">单号：{{dataSource.id}}</td>
          </tr>
          <tr class="my-tr">
            <td class="my-td my-right" width="13%">搭建商名称:</td>
            <td class="my-td" width="31%" colspan="3">{{dataSource.build_corp}}</td>
            <td class="my-td my-right" width="13%">负责人:</td>
            <td class="my-td" width="11%">{{dataSource.build_contacts}}</td>
            <td class="my-td my-right" width="13%">联系方式:</td>
            <td class="my-td" width="19%">{{dataSource.build_contacts_mobile}}</td>
          </tr>
          <tr class="my-tr">
            <td class="my-td my-right">联系邮箱:</td>
            <td class="my-td" colspan="3">{{dataSource.user_email}}</td>
            <td class="my-td my-right">搭建商地址:</td>
            <td class="my-td" colspan="3">{{dataSource.user_address}}</td>
          </tr>
          <tr class="my-tr">
            <td class="my-td my-right">参展商名称:</td>
            <td class="my-td" colspan="7">{{dataSource.corp}}</td>
          </tr>
          <tr class="my-tr">
            <td class="my-td my-right">展位号:</td>
            <td class="my-td" colspan="3">{{dataSource.booth_name}}</td>
            <td class="my-td my-right">展位面积:</td>
            <td class="my-td">{{dataSource.booth_area}}</td>
            <td class="my-td my-right">订单日期:</td>
            <td class="my-td">{{dataSource.create_time}}</td>
          </tr>
          <tr class="my-tr">
            <td class="my-td my-right">展会名称:</td>
            <td class="my-td" colspan="4">{{dataSource.p_name}}</td>
            <td class="my-td"></td>
            <td class="my-td my-right">展会地点:</td>
            <td class="my-td">{{dataSource.city}}</td>
          </tr>
          <tr class="my-tr">
            <td class="my-td my-right">展会日期:</td>
            <td class="my-td" colspan="3">{{dataSource.stime}}</td>
            <td class="my-td"></td>
            <td class="my-td"></td>
            <td class="my-td my-right">订单录入:</td>
            <td class="my-td">{{dataSource.create_time}}</td>
          </tr>
          <tr class="my-tr">
            <td class="my-td my-bg-aaa" colspan="8">&nbsp;</td>
          </tr>
          <tr class="my-tr">
            <td class="my-td my-center">编号</td>
            <td class="my-td my-center" colspan="3">说明</td>
            <td class="my-td my-center">数量</td>
            <td class="my-td my-center">单价</td>
            <td class="my-td my-center">金额</td>
            <td class="my-td my-center">备注</td>
          </tr>
          <template v-for="(item,i) in dataSource.list" :key="item.id">
            <tr class="my-tr">
              <td class="my-td my-center">{{ i+1 }}</td>
              <td class="my-td" colspan="3">{{ `${item.project_sort_name}（${item.des}）` }}</td>
              <td class="my-td my-center">{{ item.count }}</td>
              <td class="my-td my-center">{{ item.price }}</td>
              <td class="my-td my-center">{{ item.count * item.price }}</td>
              <td class="my-td my-center">&nbsp;</td>
            </tr>
          </template>
          <tr class="my-tr" style="background-color:#ffff00">
            <td class="my-td"></td>
            <td class="my-td" colspan="3"></td>
            <td class="my-td my-center" colspan="2">报馆费用小计</td>
            <td class="my-td my-center">{{subtotal}}</td>
            <td class="my-td"></td>
          </tr>
          <tr class="my-tr" v-if="dataSource.overdue_money" style="background-color:rgb(0 231 255)">
            <td class="my-td my-center"></td>
            <td class="my-td" colspan="3"></td>
            <td class="my-td my-center" colspan="2">逾期报馆费</td>
            <td class="my-td my-center">{{ dataSource.overdue_money }}</td>
            <td class="my-td my-center"></td>
          </tr>
          <template v-for="(item,i) in dataSource.yjList" :key="item.id">
            <tr class="my-tr">
              <td class="my-td my-center">{{ i+1 }}</td>
              <td class="my-td" colspan="3">{{ `${item.project_sort_name}（${item.des}）` }}</td>
              <td class="my-td my-center">{{ item.count }}</td>
              <td class="my-td my-center">{{ item.yj }}</td>
              <td class="my-td my-center">{{ item.count * item.yj }}</td>
              <td class="my-td my-center">&nbsp;</td>
            </tr>
          </template>
          <tr class="my-tr" style="background-color:#ffff00">
            <td class="my-td"></td>
            <td class="my-td" colspan="3"></td>
            <td class="my-td my-center" colspan="2">押金费用小计</td>
            <td class="my-td my-center">{{yjSubtotal}}</td>
            <td class="my-td"></td>
          </tr>
          <tr class="my-tr" style="background-color:#92d050">
            <td class="my-td"></td>
            <td class="my-td" colspan="3"></td>
            <td class="my-td my-center" colspan="2">合计</td>
            <td class="my-td my-center">{{dataSource.total}}</td>
            <td class="my-td"></td>
          </tr>
          <tr class="my-tr" style="background-color: #b7b6ed; color: #333; text-align: center;font-weight: 600;">
            <td class="my-td" colspan="10">其他追加费用</td>
          </tr>
          <template v-for="(item,i) in dataSource.addList" :key="item.order_add_id">
            <tr class="my-tr" v-if="item.add_money">
              <td class="my-td my-center">{{ i+1 }}</td>
              <td class="my-td" colspan="3">{{ `${item.add_name}` }}<span
                  v-if="item.add_des">{{`（${item.add_des}）`}}</span></td>
              <td class="my-td my-center">1</td>
              <td class="my-td my-center">{{ item.add_money }}</td>
              <td class="my-td my-center">{{ item.add_money }}</td>
              <td class="my-td my-center">{{item.add_status}}</td>
            </tr>
          </template>
          <tr class="my-tr" style="background-color:#ed919e">
            <td class="my-td"></td>
            <td class="my-td" colspan="3"></td>
            <td class="my-td my-center" colspan="2">最终开票金额合计</td>
            <td class="my-td my-center">{{ dataSource.kpje }}</td>
            <td class="my-td"></td>
          </tr>
          <tr class="my-tr" style="background-color:#92d050">
            <td class="my-td"></td>
            <td class="my-td" colspan="3"></td>
            <td class="my-td my-center" colspan="2">最终退回押金合计</td>
            <td class="my-td my-center">{{ dataSource.tkje }}</td>
            <td class="my-td"></td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="8">
              1.为了方便您订单的顺利执行，请您于报馆截止日期之前将款汇至我们公司。
            </td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="8">
              2.请贵公司核对确认我公司提供的订单信息。此订单只有在全额到款后才可生效，任何项目不予以退还。
            </td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="8">
              3.请务必于报馆截止日期之前付款，未收到付款视为预定不成功。
            </td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="8">
              4.我公司的帐号如下：
            </td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="8">
              收款单位名称：{{dataSource.sk}}
            </td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="8">
              开户银行名称：{{dataSource.kh}}
            </td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="8">
              收款单位帐号：{{dataSource.skPay}}
            </td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="8">
              注意：汇款时请务必在汇款底单上注明（展会简称-展位号-订单编号）我司不接受任何名义的个人汇款，因不能查明款项来源，如有以个人名义汇款的，我司将列为未付款项。
            </td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="8">
              请您在汇款后将汇款底单上传至系统，以便及时为您确认到款情况。
            </td>
          </tr>
          <tr class="my-tr">
            <td class="my-td" colspan="4">展商/搭建商负责人签字盖章：</td>
            <td class="my-td" colspan="4"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footer>
      <a-button type="primary" v-print="printObj">导出</a-button>
    </template>
  </a-modal>
</template>
<script>
import { ref, defineComponent, computed } from 'vue'
import { selectOrder } from '../../../api'
export default defineComponent({
  props: {
    ids: Object
  },
  setup(props) {
    const { ids } = props
    const printPreviewName = ref('')
    const visible = ref(false)
    const confirmLoading = ref(false)
    const dataSource = ref({ list: [], yjList: [], addList: [], modal_title_active: false })
    const subtotal = computed(() => {
      let num = 0
      dataSource.value.list.forEach(item => {
        num += item.price * item.count
      })
      return num
    })
    const yjSubtotal = computed(() => {
      let num = 0
      dataSource.value.yjList.forEach(item => {
        num += item.yj * item.count
      })
      return num
    })
    const otherSubtotal = computed(() => {
      let num = 0
      dataSource.value.addList.forEach(item => {
        if ((item.add_name).indexOf('追加') !== -1) {
          num -= item.add_money
        } else {
          num += item.add_money
        }
      })
      return num
    })
    const otherSubtotal1 = computed(() => {
      let num = 0
      dataSource.value.addList.forEach(item => {
        if ((item.add_name).indexOf('追加') !== -1) {
        } else {
          num += item.add_money
        }
      })
      return num
    })
    const showModal = () => {
      visible.value = true
      init(ids.pid, ids.id)
    }
    const pdfDom = ref(null)
    // const a = getCurrentInstance()
    // const getFooterElement = (remainingHeight, fillingHeight = 170) => {
    //   const newNode = document.createElement('tr')
    //   newNode.style.borderTop = '1px solid #aaa'
    //   newNode.style.height = remainingHeight + fillingHeight + 'px' // pdf截断需要一个空白位置
    //   return newNode
    // }
    const init = async (project_id, id) => {
      const res = await selectOrder(id, 1)
      console.log(res);
      dataSource.value = { ...res.order, tkje: res.tkje, kpje: res.kpje }
      const data = initArrManage(res.costList)
      let arr = []
      data.arr.forEach(item => {
        if (item.price !== 0) {
          arr.push(item)
        }
      })
      dataSource.value.list = arr
      dataSource.value.yjList = data.yjArr
      dataSource.value.addList = res.addList
      printPreviewName.value = `${res.order.id}_${res.order.booth_name}_${res.order.corp}_${res.order.build_corp}`
      const date = new Date(dataSource.value.create_time)
      const Y = date.getFullYear()
      const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      console.log(Y + '-' + M + '-' + D)
      dataSource.value.create_time = Y + '-' + M + '-' + D
    }
    const initArrManage = (data) => {
      const arr = []
      const yjArr = []
      data.forEach(item => {
        // if (item.count>0&&item.project_sort_name.indexOf('押金')!==-1) {
        //   let a = {...item}
        //   a.yj = a.price
        //   yjArr.push(a)
        // }else
        if (item.count > 0) {
          if (item.yj > 0) {
            const a = { ...item }
            a.project_sort_name = a.project_sort_name + '押金'
            yjArr.push(a)
          }
          arr.push(item)
        }
      })

      return { arr, yjArr }
    }
    // 对话框标题移入
    const changeActive = () => {
      dataSource.value.modal_title_active = true
    }
    // 对话框标题移出
    const removeActive = () => {
      dataSource.value.modal_title_active = false
    }
    return {
      visible,
      confirmLoading,
      showModal,
      dataSource,
      subtotal,
      yjSubtotal,
      otherSubtotal,
      otherSubtotal1,
      pdfDom,
      printObj: {
        id: 'printMe',
        popTitle: '展会申报系统',
        extraCss: 'https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        beforeOpenCallback(vue) {
          confirmLoading.value = true
          document.title = printPreviewName.value
        },
        closeCallback(vue) {
          confirmLoading.value = false
          document.title = '展会申报系统'
        }
      },
      changeActive,
      removeActive
    }
  }
})
</script>
<style scoped lang='less'>
.my-mask {
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100vh - 200px);
  background-color: rgba(255, 255, 255, 0.9);
}

.my-mask>div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}

.diy-modal-title {
  vertical-align: middle;

  span {
    vertical-align: middle;
  }

  .confusion {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;

    img {
      width: 18px;
      height: 18px;
    }

    .diy-confusion-show {
      position: absolute;
      top: 34px;
      left: -30px;
      width: 460px;
      padding: 10px;
      font-size: 14px;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0 0 10px #ccc;

      .sanjiao {
        position: absolute;
        top: -10px;
        left: 30px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
      }
    }
  }
}

#printMe {
  box-sizing: border-box;
  border-right: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  width: 100%;
}

.my-table {
  font-size: 18px;
  color: #000;
  box-sizing: border-box;
}

.my-td {
  border-top: 1px solid #aaa;
  border-left: 1px solid #aaa;
  padding: 4px;
  font-size: 14px;
}

.my-center {
  text-align: center;
}

.my-right {
  text-align: right;
}

.my-16-bold {
  font-size: 16px;
  font-weight: bold;
}

.my-bg-aaa {
  background-color: rgb(199, 197, 197);
}
.my-title{
  width: 100%;
  display: flex;
  align-items: center;
}
.my-title img{
  max-width: 100px;
  max-height: 40px;
  object-fit: contain;
}

.my-title span {
  display: inline-block;
  font-size: 20px;
  padding-left: 10px;
  vertical-align: middle;
  font-weight: bold;
  overflow: hidden;
  // text-overflow: ellipsis;
  // box-sizing: border-box;
  // white-space: nowrap;
  // width: 600px;
}
</style>

<style lang="less" >
.preview {
  top: 50px;
}

.preview .ant-modal-header {
  background-color: rgba(243, 241, 241, 0.63);
}

.preview .ant-modal-title {
  font-size: 18px;
  font-weight: 600;
}

.preview .ant-modal-body {
  padding: 20px;
  overflow-y: auto;
  height: calc(100vh - 200px);
  overflow-x: hidden;
}
</style>
