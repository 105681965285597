<template>
  <!-- <p v-if="feedback.flag" class="my-err">{{feedback.text}} <img @click="feedback.flag = false" src="../../../assets/close.png" alt=""></p> -->
  <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false" :row-key="(r,i)=>i">
    <template #des="{ record }">
      <span v-if="!record.children1">{{record.des}}</span>
      <a-select
        disabled
        v-else
        v-model:value="record.des_id"
        style="width: 220px"
        @change="handleChange(record)"
      >
        <template v-for="item in record.children1" :key="item.cost_id">
        <a-select-option :value="item.cost_id">{{item.des}}</a-select-option>
        </template>
      </a-select>
    </template>

    <template #price="{ record }">
      <span class="price-logo">{{record.price}}</span>
    </template>
    <template #yj="{ record }">
      <span class="price-logo">{{record.yj}}</span>
    </template>
    <template #count="{ record }">
      <a-input-number id="inputNumber" v-model:value="record.count" :min="0" :step="0.1" string-mode disabled />
    </template>
    <template #operation="{ record }">
      <span class="price-logo" >{{record.price*record.count}}</span>
    </template>
    <template #operationYj="{ record }">
      <span class="price-logo" >{{record.yj*record.count}}</span>
    </template>
    <template #footer>
      <div class="my-tr">
        <div class="my-td" style="width:20%;text-align:end;">
          费用小计
        </div>
        <div class="my-td" style="width:55%"></div>
        <div class="my-td" style="width:12.5%;text-align:end;">
          <a-button disabled block><span class="price-logo">{{pavilionStore.doubleCosts}}</span></a-button>
        </div>
        <div class="my-td" style="width:12.5%;text-align:end;">
          <a-button disabled block><span class="price-logo">{{pavilionStore.doubleDeposit}}</span></a-button>
        </div>
      </div>
      <div class="my-tr" v-if="pavilionStore.doubleOverdue">
        <div class="my-td" style="width:20%;text-align:end;">
          逾期金额
        </div>
        <div class="my-td" style="width:55%"></div>
        <div class="my-td" style="width:12.5%;text-align:end;">
          <a-button disabled block><span class="price-logo">{{pavilionStore.doubleProportion}}</span></a-button>
        </div>
        <div class="my-td" style="width:12.5%;text-align:end;">
        </div>
      </div>
      <div class="my-tr">
        <div class="my-td" style="width:20%;text-align:end;border-bottom:none">
          合计金额
        </div>
        <div class="my-td" style="width:67.5%;border-bottom:none"></div>
        <div class="my-td" style="width:12.5%;text-align:end;border-bottom:none;border-bottom:none">
          <a-button disabled block>
            <span  class="price-logo">{{pavilionStore.doubleTotal}}</span>
            <!-- <span v-else class="price-logo">{{costs+deposit}}</span> -->
            </a-button>
        </div>
      </div>
    </template>
  </a-table>
  <div style="height:30px"></div>
   
  
</template>
<script>
import { computed, defineComponent, onMounted, reactive, ref, getCurrentInstance, watch, createVNode } from 'vue';
import { CheckOutlined, EditOutlined, ExclamationCircleOutlined  } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';
import  { usePavilionStore }  from '../../../store';
export default defineComponent({
  components: {
    CheckOutlined,
    EditOutlined,
  },
  props:{
    amendItemFun:Function,
    feedback:String,
  },
  setup(props) {
    const pavilionStore = usePavilionStore()
    const { amendItemFun } = props
    const columns = [
      {
        title: '项目名称',
        dataIndex: 'project_sort_name',
        width: '20%',
      },
      {
        title: '项目描述',
        dataIndex: 'des',
        width: '20%',
        slots: {
          customRender: 'des',
        },
      },
      {
        title: '单价（元）',
        dataIndex: 'price',
        align:'center',
        width:120,
        slots: {
          customRender: 'price',
        },
      },
      {
        title: '押金单价（元）',
        dataIndex: 'yj',
        align:'center',
        width:140,
        slots: {
          customRender: 'yj',
        },
      },
      {
        title: '数量',
        dataIndex: 'count',
        width:140,
        align:'center',
        slots: {
          customRender: 'count',
        },
      },
      {
        title: '金额（元）',
        dataIndex: 'operation',
        align:'center',
        width:'12.5%',
        slots: {
          customRender: 'operation',
        },
      },
      {
        title: '押金（元）',
        dataIndex: 'operationYj',
        align:'center',
        width:'12.5%',
        slots: {
          customRender: 'operationYj',
        },
      },
    ];
    const { proxy } = getCurrentInstance()
    // 表格数据
    const dataSource = ref(computed(()=>{
      let list = [];
      if (!pavilionStore.initTableList.data) {
        return []
      }
      (pavilionStore.initTableList.data).forEach((item)=>{
        if (item.count) {
          list.push(item)
        }
      })
      return list
    }));
    let dataCopy = ref(computed(()=>pavilionStore.initTableList.arrChildren))

    const addsCurrentlySelectedItem = ref(1)
    // 多选弹窗状态
    const visible = ref(false);

    // 函数方法
    const myComputed = (a,b)=> computed({ get(){ return pavilionStore[a][b] }, set(val){ pavilionStore[a][b] = val } })

    // 错误
    const feedback = ref({
      text: myComputed('reviseUserinfo', 'feedback3'),
      flag: myComputed('reviseUserinfo', 'feedback3')
    })
  
    // 弹窗显示隐藏
    const showModal = () => {
      visible.value = true;
    };
    const handleOk = () => {
      visible.value = false;
      dataSource.value.push({...(dataCopy.value.filter(item => item.cost_id == addsCurrentlySelectedItem.value))[0]});
    };

    

    const handleChange = (obj) =>{
      let item = (obj.children1.filter(item => item.cost_id == obj.des_id))[0]
      obj.des_id = item.cost_id
      obj.des = item.des
      obj.price = item.price
      obj.yj = item.yj
    }
    const onSubmit = () =>{
      amendItemFun(3)
    }

    const showConfirm = () => {
      Modal.confirm({
        title: () => '确认提交？',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => '确认提交，30分钟内可进行修改；超过30分钟将进入审核流程...',

        onOk() {
          onSubmit()
        },

        onCancel() {},
      });
    };

    // watch(
    //   props,
    //   (val)=>{
    //     if (val.initData.length) {
    //       let list = [...val.initData]
    //       let arr= []
    //       list.forEach(item => {
    //         let key = dataCopy.value.findIndex((citem,i)=> citem.children1.findIndex((kitem)=>kitem.cost_id == item.des_id)>=0 ?1:undefined)
    //         if (key == -1) {
    //           arr.push({...item})
    //         }else{
    //           let da = {...item,children1:[...dataCopy.value[key].children1]}
    //           da.des_id=da.des_id*1
    //           arr.push({...da})
    //         }
    //       });
          
    //       dataSource.value = arr
    //       feedback.value.flag = props.feedback.length
    //       feedback.value.text = props.feedback
    //     }
    //   }
    // )
    return {
      columns,
      handleOk,
      dataSource,
      dataCopy,
      // deposit,
      // costs,
      visible,
      showModal,
      addsCurrentlySelectedItem,
      handleChange,
      onSubmit,
      feedback,
      showConfirm,
      pavilionStore
    };
  },
});
</script>
<style lang="less">
.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
.ant-table.ant-table-bordered .ant-table-footer{
  padding: 0;
  border-right: none;
}
.my-row{
  border-bottom: 1px solid #f0f0f0;
  box-sizing: border-box;
}
.my-cell{
  padding: 10px 16px;
  box-sizing: border-box;
  border-right: 1px solid #f0f0f0;
  // border-left: 1px solid #f0f0f0;
}
.my-cell-text{
  padding: 5px 24px 5px 5px;
}
</style>
<style scoped>
.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
  color: #333;
}
.my-tr{
  display: flex;
}
.my-td{
  padding: 16px;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
</style>