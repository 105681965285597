<template>
  <p v-if="feedback.flag" class="my-err">{{feedback.text}} <img @click="feedback.flag = false" src="../../../assets/close.png" alt=""></p>
  <a-form
    class="one-basic"
    ref="formRef"
    :model="formState"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-item label="项目名称" name="p_name">
      <a-input v-model:value="formState.p_name" disabled/>
    </a-form-item>
    <a-form-item  label="展馆号" name="aid">
      <a-select v-model:value="formState.aid" style="width: 200px">
        <template v-for="item in selectList" :key="item.aid">
        <a-select-option :value="item.aid">{{item.tube}}</a-select-option>
        </template>
      </a-select>
    </a-form-item>
    <a-form-item  label="展位号" name="booth_name">
      <a-input v-model:value="formState.booth_name" style="width:200px" />
    </a-form-item>
    <a-form-item label="展区面积(平方米)" name="booth_area">
      <!-- <a-input v-model:value="formState.booth_area" style="width:200px"  /> -->
      <a-input-number v-model:value="formState.booth_area" style="width:200px" :step="0.01" :min="1" :max="10000" />
    </a-form-item>
    <a-form-item label="参展企业公司全称" name="corp">
      <a-input v-model:value="formState.corp" />
    </a-form-item>
    <a-form-item label="参展商联系人" name="corp_contacts">
      <a-input v-model:value="formState.corp_contacts" />
    </a-form-item>
    <a-form-item label="参展商负责人手机号" name="corp_contacts_mobile">
      <a-input v-model:value="formState.corp_contacts_mobile" />
    </a-form-item>
    <a-form-item ref="build_corp" label="搭建企业公司全称" name="build_corp">
      <a-input v-model:value="formState.build_corp" :disabled="true" />
    </a-form-item>
    <a-form-item ref="build_contacts" label="搭建商现场安全负责人" name="build_contacts">
      <a-input v-model:value="formState.build_contacts" :disabled="true" />
    </a-form-item>
    <a-form-item ref="build_contacts_mobile" label="搭建商负责人手机号" name="build_contacts_mobile">
      <a-input v-model:value="formState.build_contacts_mobile" :disabled="true" />
    </a-form-item>
    <a-form-item style="padding-top:20px" :wrapper-col="{ span: 4, offset: 10 }">
      <!-- <a-button v-if="projectFlag" @click="resetForm">暂存</a-button> -->
      <a-button type="primary" @click="onSubmit" block>下一步</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { message } from 'ant-design-vue'
import { computed, defineComponent, reactive, ref, toRaw } from 'vue'
import  {usePavilionStore}  from '../../../store';
export default defineComponent({
  props: {
    amendItemFun: Function,
    feedback: String
  },
  setup(props) {
    const pavilionStore = usePavilionStore()
    const { amendItemFun } = props
    const formRef = ref()
    const rules = {
      aid: [
        {
          required: true,
          message: '请选择展馆（展区）',
          trigger: 'change',
          type: 'number'
        }
      ],
      booth_name: [
        {
          required: true,
          message: '请输入展位号',
          trigger: 'change'
        }
      ],
      booth_area: [
        {
          required: true,
          message: '请输入展位面积',
          trigger: 'change',
          type: 'number'
        }
      ],
      corp: [
        {
          required: true,
          message: '请输入参展企业公司全称',
          trigger: 'change'
        }
      ],
      corp_contacts: [
        {
          required: true,
          message: '请输入参展商联系人',
          trigger: 'change'
        }
      ],
      corp_contacts_mobile: [
        {
          required: true,
          message: '请输入参展商负责人手机号',
          trigger: 'change'
        }
      ],
      build_corp: [
        {
          required: true,
          message: '请输入搭建企业公司全称',
          trigger: 'change'
        }
      ],
      build_contacts: [
        {
          required: true,
          message: '请输入搭建商现场安全负责人',
          trigger: 'change'
        }
      ],
      build_contacts_mobile: [
        {
          required: true,
          message: '请输入搭建商负责人手机号',
          trigger: 'change'
        }
      ]
    }

    // 函数方法
    const myComputed = (a,b)=> computed({ get(){ return pavilionStore[a][b] }, set(val){ pavilionStore[a][b] = val } })

    // 展馆号下拉数据
    let selectList = ref(computed(()=> pavilionStore.project.areaList) || [])
    // 错误提示显示数据
    const feedback = ref({
      text: myComputed('reviseUserinfo', 'feedback1'),
      flag: myComputed('reviseUserinfo', 'feedback1')
    })
    // 数据
    const formState = reactive({
      p_name: myComputed('project', 'p_name'),
      aid: myComputed('reviseUserinfo', 'aid'),
      booth_name: myComputed('reviseUserinfo', 'booth_name'),
      booth_area: myComputed('reviseUserinfo', 'booth_area'),
      corp: myComputed('reviseUserinfo', 'corp'),
      corp_contacts: myComputed('reviseUserinfo', 'corp_contacts'),
      corp_contacts_mobile: myComputed('reviseUserinfo', 'corp_contacts_mobile'),
      build_corp: myComputed('user', 'corp_name'),
      build_contacts: myComputed('user', 'name'),
      build_contacts_mobile: myComputed('user', 'mobile'),
      examine: myComputed('reviseUserinfo', 'examine'),
    })
    
    // 下一步
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          amendItemFun(1)
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
    
    // 暂存函数
    const resetForm = () => {
      // formRef.value.resetFields();
      message.success('别点啦，还在开发呢')
    }

    return {
      pavilionStore,
      formRef,
      labelCol: {
        span: 9
      },
      wrapperCol: {
        span: 12
      },
      rules,
      selectList,
      feedback,
      formState,
      onSubmit,
      resetForm,
    }
  }
})
</script>
<style scoped lang="less">
.one-basic .ant-input {
  width: 400px;
  margin: 0 8px 8px 0;
}

</style>
