<template>
  <div class="clearfix">
    <a-upload
      :accept="obj.accept"
      list-type="picture-card"
      v-model:file-list="fileList"
      @preview="handlePreview"
      @change="handleChange"
      :before-upload="beforeUpload"
      :customRequest="customRequest"
    >
      <div v-if="fileList.length < (obj.size*1?obj.size*1:5)">
        <plus-outlined />
        <div class="ant-upload-text">上传</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, toRaw, watch, computed } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { postUploadBackName } from '../../api'

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default defineComponent({
  components: {
    PlusOutlined
  },
  props: {
    obj: {
      type: Object,
      default: 0
    }
  },
  setup (props) {
    const { obj } = props
    // console.log(obj);
    const previewVisible = ref(false)
    const previewImage = ref('')
    const fileList = ref(props.obj.fileList || [])
    const handleCancel = () => {
      // console.log(123)
      previewVisible.value = false
    }
    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }

      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }
    const beforeUpload = file => {
      let fileSize = parseInt(obj.fileSize)
      // console.log('fileSize', fileSize, file.size / 1024 / 1024)
      const isLt2M = file.size / 1024 / 1024 < fileSize
      if (!isLt2M) {
        file.status = 'error'
        // message.error('上传大小不得超过10M!')
      }

      return isLt2M
    }

    // const a = file => {
    //   var files
    //   var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2)
    //   var read = new FileReader()
    //   read.readAsDataURL(file)
    //   read.onload = function () {
    //     var w = 200
    //     var h = 200
    //     var canvas = document.createElement('canvas')
    //     var ctx = canvas.getContext('2d')
    //     var getBase64
    //     canvas.setAttribute('width', w)
    //     canvas.setAttribute('height', h)
    //     ctx.drawImage(this, 0, 0, w, h)
    //     if (fileSize < 1) {
    //       base64 = canvas.toDataURL(file['type'], 0.5)
    //     }else if (fileSize > 1 && fileSize < 2) {
    //       base64 = canvas.toDataURL(file['type'], 0.5)
    //     }else {
    //       base64 = canvas.toDataURL(file['type'], 0.5)
    //     }
    //     files = that.dataURLtoFile(base64, file.name)
    //   }
    // }

    const handleChange = (info) => {
      // console.log('handleChange', info)
      if (info.file.status === 'removed') {
        // console.log('removed', obj);
        console.log(info, obj, fileList)
        // obj.itemArrFun(obj.id, info.fileList)
      }
      if (info.file.status === 'error') {
        let fileSize = parseInt(obj.fileSize)
        message.error(`上传大小不得超过${fileSize}!`)
      }
    }
    const customRequest = async(info) => {
      let { file } = info
      let arr = file.name.split('.')
      var timestamp = `${new Date().getTime()}`
      let num = Math.floor(Math.random() * 99)
      file = new File([file], `${obj.name}_${timestamp.substring(timestamp.length - 8)}${num}.${arr[arr.length - 1]}`, { type: file.type })
      const formData = new FormData()
      formData.set('file', file)
      const res = await postUploadBackName(formData, obj.url, obj.type)
      fileList.value[fileList.value.length - 1].status = 'done'
      fileList.value[fileList.value.length - 1].url = res
      fileList.value[fileList.value.length - 1].name = `${obj.name}_${timestamp.substring(timestamp.length - 8)}${num}.${arr[arr.length - 1]}`
      fileList.value[fileList.value.length - 1].file_type = file.type
      obj.itemArrFun(obj.id, fileList.value)
      // console.log(file)
    }
    const acceptCpud = computed(() => {
      let a = obj.acceptobj
      if (!a) {
        return ''
      }
      let arr = a.split('、')
      console.log(a)
      let acceptStr = ''
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index]
        acceptStr += `.${element}`
      }
      return acceptStr
    })

    watch(
      props,
      (val) => {
        if (val.obj) {
          // console.log(toRaw(val.obj.fileList))
          let newArr = toRaw(val.obj.fileList)
          newArr.forEach((item, i) => {
            if (item.status === 'removed') {
              newArr.splice(i, 1)
            }
          })
          fileList.value = newArr
        }
      }
    )

    return {
      previewVisible,
      previewImage,
      fileList,
      handleCancel,
      handlePreview,
      handleChange,
      beforeUpload,
      customRequest,
      acceptCpud
    }
  }
})
</script>

<style>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-image{
  vertical-align: top;
  cursor: pointer;
}
.preview:hover .preview-mask{
  z-index: -1;
}

.ant-form-item-control{
  width: 100%;
}
</style>
