<template>
  <a-button  size="small" type="primary" @click="showModal" ghost>查看</a-button>
  <a-modal :bodyStyle="{ maxHeight: '70vh', overflowY: 'auto',  }" v-model:visible="visible" width="60%" title="查看" @ok="handleOk">
    <a-row class="arow" >
      <a-col :span="24" ><h2 style="text-align: center;">报馆基本信息</h2></a-col>
      <a-col class="item" :span="12" v-for="item in list.basic" :key="item.ord"><label for="">{{item.name}}：</label><span>{{pavilionStore.reviseUserinfo[item.ord]}}</span></a-col>
      <a-col :span="24" ><h2 style="text-align: center;">结构图及特证材料</h2></a-col>
      <a-col class="item-img" :span="12" v-for="(item,i) in pavilionStore.initDataUpdate" :key="i">
        <!-- <div v-for="(kitem) in item" :key="kitem.id">
          <label for="">{{kitem.id}}</label><span>{{kitem.id}}</span>
        </div> -->
        <div class="my-title">{{item.file_name}}<span v-if="item.file_des||item.file_type||item.size">(<span v-if="item.file_des" style="color:red">{{item.file_des}}&nbsp;&nbsp;</span><span v-if="item.file_type" style="color:red">格式：{{item.file_type}}&nbsp;&nbsp;</span><span v-if="item.size" style="color:red">大小：{{item.size}}</span>)</span></div>
        <!-- {{dataList[1][item.id]}}123 -->
        <template v-for="(kItem) in pavilionStore.reviseDataUpdate[item.id]" :key="kItem.id">
          <a-image v-if="((kItem.file_type).indexOf('image') != -1)"  class="preview-img" :width="104"  :src="kItem.url" />
          <div v-else class="ant-image" style="width:104px">
            <a :href="kItem.url" target="_blank" rel="noopener noreferrer">
              <div class="kele-word" >
                <span>
                  <FileWordOutlined style="font-size:40px" />
                </span>
                <p>{{kItem.file_name}}</p>
              </div>
            </a>
          </div>
        </template>

      </a-col>
      <a-col :span="24" ><h2 style="text-align: center;">缴费明细</h2></a-col>
      <a-col :span="24"><look-four-other feedback="" :amendItemFun="()=>{}" :projectFlag="0"/></a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive } from 'vue'
import { FileWordOutlined } from '@ant-design/icons-vue'
import  { usePavilionStore }  from '../../../store'
import { LookFourOther } from './index'
import { selectOrder, initFiles } from '../../../api/index'
var list1 = [
  {
    name: '项目名称',
    ord: 'p_name'
  },
  {
    name: '展馆号',
    ord: 'tube'
  },
  {
    name: '展位号',
    ord: 'booth_name'
  },
  {
    name: '展区面积',
    ord: 'booth_area'
  },
  {
    name: '参展企业',
    ord: 'corp'
  },
  {
    name: '参展联系人',
    ord: 'corp_contacts'
  },
  {
    name: '参展负责人手机号',
    ord: 'corp_contacts_mobile'
  },
  {
    name: '搭建企业',
    ord: 'build_corp'
  },
  {
    name: '搭建商安全负责人',
    ord: 'build_contacts'
  },
  {
    name: '搭建商负责人手机号',
    ord: 'build_contacts_mobile'
  }
]
export default defineComponent({
  props: {
    ids: Object
  },
  components: {
    LookFourOther,
    FileWordOutlined
  },
  setup({ ids }) {
    const pavilionStore = usePavilionStore()

    const visible = ref(false)
    const dataList = ref([{}, {}, []])
    const list = reactive({ basic: list1, material: [] })

    const showModal = async() => {
      let user_id = window.sessionStorage.getItem('user-id')
      pavilionStore.resNewspaper({ user_id, p_id: ids.pid, data_id: ids.id, flag:true })
      // await init(ids.pid)
      // window.sessionStorage.setItem('project_id', ids.pid)
      // console.log(ids.id)
      // const res = await selectOrder(ids.id, ids.pid)
      // echoList(res)
      // console.log(res)
      visible.value = true
    }
    const echoList = (data) => {
      let obj1 = {}, obj2 = {}, arr3 = []
      // for (const key in data) {
      //   if (Object.hasOwnProperty.call(data, key)&&key.indexOf('fileList') >= 0) {
      //     const element = data[key];
      //     // if (key.replace(/[^0-9]/ig,"")<=8) {
      //       obj2[key] = element
      //     // }else{
      //       // obj3[key] = element
      //     // }
      //   }
      // }
      // let { feedback1, feedback2, feedback3, feedback4 } = data.order
      // feedback.value = { feedback1, feedback2, feedback3, feedback4 }
      obj1 = data.order
      obj2 = data.fileList
      arr3 = data.costList
      dataList.value = [obj1, obj2, arr3]
    }

    const handleOk = e => {
      console.log(e)
      visible.value = false
    }
    const init = async() => {
      // window.setTimeout(async()=>{
      // let project_id = window.sessionStorage.getItem('project_id')
      console.log(ids.pid)
      const res = await initFiles(ids.pid)
      list.material = res
      console.log(res)
      // },300)
    }
    // onMounted(init())

    return {
      visible,
      dataList,
      showModal,
      handleOk,
      list,
      pavilionStore
    }
  }

})
</script>

<style lang="less" scoped>
.arow{
  background: url('../../../assets/detail_bg.png') no-repeat;
  background-position: center 0;
}

h2{
  padding: 40px 0 20px;
  font-weight: bold;
}
.item{
  line-height: 36px;
  label{
    display: inline-block;
    width: 30%;
    color: #757575;
    text-align: end;
    min-width: 144px;
  }
  span{
    font-weight: bold;
  }
}
.item-img{
  line-height: 36px;
  label{
    display: block;

  }
}
/deep/.preview-img{
  width: 104px;
  height: 104px;
  object-fit: cover;
}
/deep/.ant-image{
  margin-right: 6px;
  height: 104px;
  box-shadow: 0 0 4px #ccc;
  margin-bottom: 6px;
}
.kele-word{
  position: relative;
  width: 104px;
  height: 104px;
  display: inline-flex;
  align-items: flex-end;
  background-color: #f5f5f5;
  box-shadow: 0 0 4px #ccc;
  padding: 6px;
  margin-bottom: 6px;
}
.kele-word > span{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.kele-word > p{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  margin-bottom: 0;
  line-height: 1;
}
</style>