<template>
  <p v-if="feedback.flag" class="my-err">{{feedback.text}} <img @click="feedback.flag = false" src="../../../assets/close.png" alt=""></p>
  <a-form
    ref="formRef"
    :rules="rules"
    :model="formState"
    layout="vertical"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-row>
      <template v-for="(item) in list" :key="item.id">
      <a-col :span="12" class="item">
        <a-form-item label="" :name="item.id">
          <div class="my-title">{{item.file_name}}<span v-if="item.file_des||item.file_type||item.size">(<span v-if="item.file_des" style="color:red">{{item.file_des}}&nbsp;&nbsp;</span><span v-if="item.file_type" style="color:red">格式：{{item.file_type}}&nbsp;&nbsp;</span><span v-if="item.size" style="color:red">大小：{{item.size}}</span>)</span></div>
          <my-upload :obj="{ id:item.id, name:item.file_name, size:item.quantity?item.quantity:0,fileSize:item.size, fileList:pavilionStore.reviseDataUpdate[item.id], url:'upload', type: 'order', accept: item.accept, itemArrFun }" />
        </a-form-item>
      </a-col>
      </template>
    </a-row>
    <a-form-item style="padding-top:20px" :wrapper-col="{ span: 4, offset: 10 }">
      <!-- <a-button v-if="projectFlag" @click="resetForm">暂存</a-button> -->
      <a-button type="primary" @click="onSubmit" block>下一步</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { message } from 'ant-design-vue'
import { defineComponent, onMounted, reactive, ref, toRaw, computed } from 'vue'
import  {usePavilionStore}  from '../../../store';
import MyUpload from '../../../components/smallParts/NewMyUpload.vue'

export default defineComponent({
  components: {
    MyUpload
  },
  props:{
    amendItemFun:Function,
    feedback:String,
  },
  setup(props) {
    const pavilionStore = usePavilionStore()
    const { amendItemFun } = props
    const formRef = ref();
    // const list = ref([])
    const formState = ref(computed(()=>pavilionStore.reviseDataUpdate))
    const rules = {}
    // 函数方法
    const myComputed = (a,b)=> computed({ get(){ return pavilionStore[a][b] }, set(val){ pavilionStore[a][b] = val } })

    const feedback = ref({
      text: myComputed('reviseUserinfo', 'feedback2'),
      flag: myComputed('reviseUserinfo', 'feedback2')
    })

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          nextStep()
        })
        .catch(error => {
          console.log('error', error);
        });
    };
    
    const nextStep = ()=>{
      amendItemFun(2)
    }

    const resetForm = () => {
      // formRef.value.resetFields();
      message.success('别点啦，还在开发呢')
    }
    const itemArrFun = (id, arr) => {
      console.log(id, arr)
      pavilionStore.reviseDataUpdate[id] = [...arr]
    }
    
    const list = computed(()=>{
      pavilionStore.initDataUpdate.forEach((item) => {
        rules[item.id] = [
          {
            type: 'array',
            required: true,
            message: '请按照规则上传文件',
            trigger: 'blur',
          },
        ]
        // console.log(item);
        // console.log(formState.value);
      })
      return pavilionStore.initDataUpdate
    })

    // const acceptCpud = computed(()=>{
    //   let a = obj.acceptobj
    //   if (!a) {
    //     return ''
    //   }
    //   let arr = a.split('、')
    //   console.log(a);
    //   let acceptStr = ''
    //   for (let index = 0; index < arr.length; index++) {
    //     const element = arr[index];
    //     acceptStr+=`.${element}`
    //   }
    //   return acceptStr
    // })

    return {
      list,
      formRef,
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 22,
      },
      other: '',
      rules,
      formState,
      onSubmit,
      resetForm,
      itemArrFun,
      feedback,
      pavilionStore,
    };
  },
});
</script>


