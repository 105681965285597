<template>
  <p v-if="feedback.flag" class="my-err">{{feedback.text}} <img @click="feedback.flag = false" src="../../../assets/close.png" alt=""></p>
  <a-form
    ref="formRef"
    :model="formState"
    :rules="rules"
    layout="vertical"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-row>
      <template v-for="item in list" :key="item.id">
      <a-col :span="12" class="item">
        <a-form-item label="" :name="item.id">
          <div class="my-title">{{item.name}}<span v-if="item.title">(<span style="color:red">{{item.title}}</span>)</span></div>
          <my-upload v-if="projectFlag*1" :fileList="formState[item.id]" :itemArrFun="itemArrFun" :idid="item.id" />
          <template v-else v-for="(kItem,k) in formState[item.id]" :key="kItem.id">
            <a-image v-if="((kItem.file_type).indexOf('image') != -1)" class="preview-img" :width="104" :src="kItem.url" />
            <p v-else>
              <a :href="kItem.url">{{k+1}}.{{item.name}} <img src="../../../assets/downloadicon.png" width="15" height="14" alt=""></a>
            </p>
          </template>
        </a-form-item>
      </a-col>
      </template>
    </a-row>
    
    <a-form-item style="padding-top:20px" :wrapper-col="{ span: 12, offset: 9 }">
      <!-- <a-button v-if="projectFlag" @click="resetForm">暂存</a-button> -->
      <a-button style="margin-left: 20px"  type="primary" @click="onSubmit">下一步</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { message } from 'ant-design-vue';
import { defineComponent, reactive, ref, toRaw, watch } from 'vue';
import MyUpload from '../../../components/smallParts/MyUpload.vue';
const list = [
  {
    id: 'fileList9',
    title: '格式：word',
    name: '保险',
  },
  {
    id: 'fileList10',
    title: '格式：word',
    name: '特装展位施工申请表',
  },
  {
    id: 'fileList11',
    title: '格式：word',
    name: '特装展位搭建委托书',
  },
  {
    id: 'fileList12',
    title: '双层展台搭建商填写；格式：word',
    name: '双层展台施工安全保证书',
  },
  {
    id: 'fileList13',
    title: '格式：word',
    name: '电工证',
  },
  {
    id: 'fileList14',
    title: '格式：word',
    name: '特装展位参展商安全责任保证书',
  },
  {
    id: 'fileList15',
    title: '格式：word',
    name: '特装展位施工安全保证书',
  },
  {
    id: 'fileList16',
    title: '格式：word',
    name: '展览施工管理处罚规定',
  },
  {
    id: 'fileList17',
    title: '盖公章；格式：jpg；大小:10M内',
    name: '搭建材料检测报告',
  },
]
export default defineComponent({
  components:{
    MyUpload
  },
  props:{
    amendItemFun:Function,
    initData:Object,
    projectFlag:Number,
    feedback:String,
  },
  setup(props) {
    const { amendItemFun } = props
    const formRef = ref();
    const formState = reactive({
      fileList9: [],
      fileList10: [],
      fileList11: [],
      fileList12: [],
      fileList13: [],
      fileList14: [],
      fileList15: [],
      fileList16: [],
      fileList17: [],
    });
    const projectFlag = ref(1)
    const feedback = ref({
      text:'',
      flag:0
    })
    const rules = {}
    for (let index = 9; index < 17; index++) {
      rules[`fileList${index}`] = [
        {
          type: 'array',
          required: true,
          message: '请按照规则上传图片',
          trigger: 'blur',
        },
      ]
    }

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          nextStep()
        })
        .catch(error => {
          console.log('error', error);
        });
    };
    const nextStep = ()=>{
      let obj = toRaw(formState)
      let arr = []
      for (const key in obj) {
        obj[key].forEach(item => {
          arr.push({
            id:item.id,
            file_type:item.file_type,
            order_sort_id:key.split('fileList')[1]*1,
            url:item.url
          })
        });
      }
      amendItemFun(3,arr)
    }

    const resetForm = () => {
      // formRef.value.resetFields();
      message.success('别点啦，还在开发呢')
    };
    const itemArrFun = (id,arr)=>{
      formState[id]=arr
    }
    watch(
      props,
      (val)=>{
        if (val.initData.fileList9) {
          for (const key in val.initData) {
            if (Object.hasOwnProperty.call(val.initData, key)) {
              const element = val.initData[key];
              formState[key] = element
            }
          }
        }
        projectFlag.value = val.projectFlag *1
        if (val.projectFlag*1) {
          feedback.value.flag = props.feedback.length
        }
        feedback.value.text = props.feedback
      }
    )

    return {
      list,
      formRef,
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 22,
      },
      other: '',
      formState,
      rules,
      onSubmit,
      resetForm,
      itemArrFun,
      projectFlag,
      feedback
    };
  },
});
</script>

